<template>
  <div class="mt-1 mx-3">
    <company-sectors> </company-sectors>
  </div>
</template>

<script>
import CompanySectors from '@/components/profile/sectors/company/index.vue';

import { mapActions } from 'vuex';

export default {
  props: {
    msg: String,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    CompanySectors,
  },
  created() {
    this.notifyQueue({ text: "Comparti di compagnia", path: "sectors.index1", level: 1 });
  },
};
</script>
